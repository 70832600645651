/**
 * Centralised location for checkout generic data updates
 */
import { ICheckoutActionResult, ICheckoutState } from '@msdyn365-commerce/global-state';
import { ICoreContext } from '@msdyn365-commerce/core';
import { Address } from '@msdyn365-commerce/retail-proxy';

const DELIVERY_ADDRESS: string = 'bsDeliveryAddress';
const CENTIRO_ADDRESS: string = 'bsCentiroDeliveryAddress';
const SHOULD_UPDATE_DELIVERY_ADDRESS: string = 'shouldUpdateDeliveryAddress';

export const setDeliveryAddress = async (checkout: ICheckoutState | undefined, shippingAddress: Address) => {
    await setCheckoutGenericData(checkout, DELIVERY_ADDRESS, shippingAddress);
};
export const getDeliveryAddress = (checkout: ICheckoutState | undefined): Address | undefined => {
    return checkout?.getCheckoutGenericData({ key: DELIVERY_ADDRESS })?.Data;
};

export const setCentiroAddress = async (checkout: ICheckoutState | undefined, centiroAddress: Address) => {
    await setCheckoutGenericData(checkout, CENTIRO_ADDRESS, centiroAddress);
};
export const getCentiroAddress = (checkout: ICheckoutState | undefined): Address | undefined => {
    return checkout?.getCheckoutGenericData({ key: CENTIRO_ADDRESS })?.Data;
};

export const setUpdateDeliveryAddressFlag = (checkout: ICheckoutState | undefined, yes: boolean) => {
    checkout?.updateExtensionProperties({
        newExtensionProperties: [{ Key: SHOULD_UPDATE_DELIVERY_ADDRESS, Value: { BooleanValue: yes } }]
    });
};
export const getUpdateDeliveryAddressFlag = (checkout: ICheckoutState | undefined) => {
    const deliveryAddressStatus = checkout?.extensionProperties?.find(property => property.Key === SHOULD_UPDATE_DELIVERY_ADDRESS);
    return deliveryAddressStatus?.Value?.BooleanValue ?? false;
};

export const cartContainsItemsForCentiroShipping = (checkout: ICheckoutState | undefined, context: ICoreContext): boolean => {
    const centiroDeliveryMode: string = context.request.app.config.deliveryModeCentiro?.toLowerCase() || 'postnord';
    return !!((checkout && checkout.checkoutCart.cart.CartLines) || []).filter(cartLine =>
        cartLine.DeliveryMode && cartLine.DeliveryMode !== ''
            ? cartLine.DeliveryMode === centiroDeliveryMode && cartLine.DeliveryMode === centiroDeliveryMode
            : false
    );
};

const setCheckoutGenericData = (checkout: ICheckoutState | undefined, key: string, data: Object): Promise<ICheckoutActionResult> => {
    if (checkout) {
        const genericData = {
            key,
            checkoutGenericData: {
                Data: data,
                SaveDataBeforeRedirect: true
            }
        };
        return checkout.setCheckoutGenericData(genericData);
    }
    return new Promise(resolve => {
        resolve({ status: 'FAILED' });
    });
};
