import React from 'react';
import { ICoreContext, ITelemetry } from '@msdyn365-commerce/core';
import { PriceComponent } from '@msdyn365-commerce/components';

interface IOrderSummaryLineProps {
    label: string;
    context: ICoreContext;
    telemetry: ITelemetry;
    price?: number;
    freeText?: string;
    toBeCalculatedText?: string;
    id: string;
    typeName: string;
    cssLabel: string;
}

/* VSI Customization - To display subamout without discount on Cart & Checkout Page */
export const _OrderSummaryLine: React.FC<IOrderSummaryLineProps> = ({
    price,
    label,
    context,
    id,
    typeName,
    toBeCalculatedText,
    freeText,
    cssLabel
}) => {
    return (
        <p className={`msc-order-summary__line-${cssLabel}`}>
            <span className='msc-order-summary__label'>{label}</span>
            {price || price === 0 ? (
                <PriceComponent
                    data={{
                        price: { CustomerContextualPrice: price }
                    }}
                    freePriceText={freeText}
                    context={context}
                    id={id}
                    typeName={typeName}
                    className='msc-order-summary__value'
                />
            ) : (
                <span className='msc-order-summary__value'>{toBeCalculatedText}</span>
            )}
        </p>
    );
};
