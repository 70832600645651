import { IComponentProps } from '@msdyn365-commerce/core';
import { CommerceProperty, Coupon } from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';

export interface ICouponCardProps extends IComponentProps {
    coupon: Coupon;
    usePrefix: string;
    availDiscountMessage: string;
    validTillMessage: string;
    compactView?: boolean;
}

export const CouponCard: React.FC<ICouponCardProps> = (props: ICouponCardProps) => {
    const {
        coupon,
        usePrefix,
        validTillMessage,
        availDiscountMessage,
        compactView,
        context: { cultureFormatter }
    } = props;
    const details =
        coupon && coupon?.ExtensionProperties!.find((coupons: CommerceProperty) => coupons.Key === 'BS_DESCRIPTION')?.Value?.StringValue;
    const discountAmount = cultureFormatter.formatCurrency(details?.split(' ')[0]!);

    //changes to test showing non-loyalty scheme coupons properly once retail server extension change deployed for testing

    // for non-loyalty scheme discounts, we just want to show the coupn's F&O description
    const couponDescription =
        coupon &&
        coupon?.ExtensionProperties!.find((coupons: CommerceProperty) => coupons.Key === 'BS_COUPONDESCRIPTION')?.Value?.StringValue;

    // should be able to use amount to work out if is loyalty scheme or not (loyalty amount > 0 and advanced loyalty flag)
    const amount = coupon?.ExtensionProperties!.find(data => data.Key === 'BS_AMOUNT')?.Value?.DecimalValue || 0;
    const isAdvancedLoyalty = (coupon?.ExtensionProperties!.find(data => data.Key === 'BS_ISADVANCEDCOUPON')?.Value?.IntegerValue || 0) > 0;
    const showAmount = isAdvancedLoyalty && amount > 0;
    return (
        <div className='reward-card-box'>
            <div className='logo-box'>
                <img src='https://images-eu-prod.cms.commerce.dynamics.com/cms/api/mgdsrmgddc/imageFileData/ME7T52?pubver=2' alt='logo' />
            </div>
            <div className='card-detail'>
                {!compactView && <h3>{showAmount ? discountAmount : couponDescription}</h3>}
                <p>
                    {compactView && !showAmount && (
                        <>
                            {couponDescription}
                            <br />
                        </>
                    )}
                    {usePrefix} <strong> {coupon && coupon.Code}&nbsp;</strong>
                    {showAmount && availDiscountMessage.replace('{0}', discountAmount)}
                </p>
                <p>
                    {validTillMessage}
                    <strong>{details && details.includes(',') ? details.split(',')[details.split(',').length - 1] : null}</strong>
                </p>
            </div>
        </div>
    );
};
