import * as React from 'react';
import { ICustomerServiceLinkData } from '../../modules/only-c-checkout';

export interface ICustomerServiceLinksProps {
    links?: ICustomerServiceLinkData[];
}

export const CustomerServiceLinks: React.FC<ICustomerServiceLinksProps> = (props: ICustomerServiceLinksProps) => {
    const { links } = props;
    return (
        <>
            {links &&
                links.map((link, index) => {
                    return (
                        <div key={index} className='feature-item'>
                            <span className={`icon-${link.icon.replace('_', '-')}`}></span>
                            {link.url ? <a href={link.url.destinationUrl}>{link.text}</a> : link.text}
                        </div>
                    );
                })}
        </>
    );
};
